<template>
  <div class="content">
    <el-card class="box-card" :body-style="{ background: '#F0F0F0' }">
      <div class="title-message">请输入信息关联公众号</div>
      <br/>
      <div :class="IsError?'error-message':'success-message'"><span
          v-show="showErrorMessage">{{ errorMessage }}</span>
      </div>
      <br/>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="客户号" prop="customerId">
          <el-input v-model="form.customerId" placeholder="请输入客户号" clearable></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="form.account" placeholder="请输入账号" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="linkWechatOpenId('form')">确定</el-button>
<!--          <el-button @click="cancel">取消</el-button>-->
        </el-form-item>
      </el-form>
<!--      <div>{{datas}}</div>-->
    </el-card>

  </div>
</template>

<script>
import md5 from 'js-md5'
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Verify',
  data() {
    return {
      message: "Hello Element Plus",
      code: '',
      showErrorMessage: true,
      IsError: true,
      errorMessage: '',
      datas: '',
      accessToken: '',
      openId: '',
      form: {
        customerId: '',
        account: '',
        password: '',
      },
      rules: {
        customerId: [
          {required: true, message: '请输入客户号', trigger: 'blur'},
        ],
        account: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      }
    };
  },
  mounted() {
    /*this.openId = this.getParamsByUrl('openid');
    if (this.openId != 'undefined' && this.openId != null && this.openId != '') {
      this.IsError = false;
      this.errorMessage = "openId=" + this.openId;
      return;
    }*/
    this.code = this.getParamsByUrl('code');
    if (this.code === 'undefined' || this.code == null || this.code === '') {
      this.showErrorMessage = true;
      this.errorMessage = "授权未获取到code信息";
      return;
    } else if (this.code != 'undefined' && this.code != null && this.code != ''){
      this.errorMessage = this.code;
    }
    /*axios.get('http://ne9gtq.natappfree.cc/mercury/WeChatAccount/GetAccessToken?code=' + this.code).then((result) => {
      if (result.data.errcode != 0) {
        this.errorMessage = '获取用户token失败：' + result.data.errmsg;
        return;
      }
      this.accessToken = result.data.access_token;
      this.openId = result.data.openid;
      this.errorMessage = 'openId=' + result.data.openid;
    }).catch((err) => {
      console.log(err);
    });*/
  },
  methods: {
    getParamsByUrl(params) {
      return new URLSearchParams(window.location.search).get(params);
    },
    linkWechatOpenId(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          /*
          * http://yiwei001.com:6005/earth/Account/AccountLinkWeChatOpenId
          * */
          axios.post('https://yiwei001.com/galaxy/earth/Account/AccountLinkWeChatOpenId', {
            customerId: this.form.customerId,
            id: this.form.account,
            password: md5(this.form.password),
            code: this.code,
            thirdPlatform: 0
          }).then((data) => {
            if (data.data.code == 0) {
              this.IsError = false;
              this.errorMessage = "绑定成功";
            } else if (data.data.code == 112) {
              this.IsError = true;
              this.errorMessage = "第三方授权失败";
            } else if (data.data.code == 113) {
              this.IsError = true;
              this.errorMessage = "第三方账号关联失败";
            } else {
              this.IsError = true;
              this.errorMessage = "绑定失败";
            }
          }).catch((err) => {
            console.log(err);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel() {

    }
  }
}
</script>
<style>
html, body {
  padding: 0px;
  margin: 0px;
}
.content {
  /*border: 1px solid red;*/
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/src/assets/bg.png');
  background-repeat: no-repeat;
}
.box-card{
  width: 300px;
}

.title-message {
  color: #333;
}

.error-message {
  height: 15%;
  text-align: center;
  font-size: xx-small;
  color: red;
}

.success-message {
  height: 15%;
  text-align: center;
  font-size: xx-small;
  color: green;
}
</style>

