<template>
  <div id="app">
    <Verify msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Verify from './views/Verify.vue'

export default {
  name: 'App',
  components: {
    Verify
  }
}
</script>

<style scoped>
#app {
  font-family: 宋体;
  text-align: center;
}
</style>
